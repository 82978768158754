<template>
  <div class="container">
    <!-- 查询条件 -->
    <analysis-query @ready="batchParams"></analysis-query>

    <!-- 表格 -->
    <el-table style="width: 100%; margin-top: 10px;"
      ref="multipleTable"
      tooltip-effect="dark"
      header-row-class-name="table-header-style"
      :data="tableData"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <!-- 姓名 -->
      <el-table-column label="姓名" prop="name" align="center" width="180px"></el-table-column>
      <!-- 部门 -->
      <el-table-column label="部门" prop="teamName" align="center"  width="180px"></el-table-column>
      <!-- 电话 -->
      <el-table-column label="电话" prop="phoneNumber" align="center"></el-table-column>
      <!-- 被浏览次数 -->
      <el-table-column label="被浏览(次)" prop="visitPv" align="center"></el-table-column>
      <!-- 被浏览人数 -->
      <el-table-column label="被浏览(人)" prop="visitUv" align="center"></el-table-column>
      <!-- 被转发次数 -->
      <el-table-column label="被转发(次)" prop="sharePv" align="center"></el-table-column>
      <!-- 被转发次数 -->
      <el-table-column label="被转发(人)" prop="shareUv" align="center"></el-table-column>
      <!-- 被点赞次数 -->
      <el-table-column label="被点赞(人)" prop="raiseupUv" align="center"></el-table-column>
      <!-- 交换联系方式 -->
      <el-table-column label="交换联系方式(次)" prop="phone" align="center"></el-table-column>
      <!-- 线索详情 -->
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button @click="redirectPage(scope.row)" type="text">线索详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AnalysisQuery from '@/websites/cms/components/AnalysisQuery'
export default {
  components: {
    'analysis-query': AnalysisQuery
  },
  data(){
    return {
      tableData: [],
      params: null
    }
  },
  methods: {
    async batchParams(data){
      let params = {rt: data.sourceType, cid: data.companyId};
      if(data.companyId != data.teamId) params.tid = data.teamId;
      params.ranges = [{from: data.times[0], to: this.$dayjs(data.times[1]).add(1, 'day').format('YYYY-MM-DD')}];
      this.params = params;
      this.getShare(params);
    },

    // 获取销售分享次数、被转发数、交换联系方式次数
    async getShare(params){
      let data = [];
      this.tableData = [];
      let visit = await this.$ZGManager.statSellerSpread({...params, evt: 'visit'});
      console.log(visit);
      if(visit.status == 200 && visit.data.length > 0) data = visit.data;
      data.map((e) => {
        e.visitUv = e.uv;
        e.visitPv = e.pv;
        e.shareUv = 0;
        e.sharePv = 0;
        e.raiseupUv = 0;
        e.phone = 0;
      });

      let share = await this.$ZGManager.statSellerSpread({...params, evt: 'share'});
      if(share.status == 200 && share.data.length > 0){
        share.data.map((e) => {
          let index = data.findIndex(item => item.xid == e.id);
          if(index == -1){
            e.visitUv = 0;
            e.visitPv = 0;
            e.shareUv = e.uv;
            e.sharePv = e.pv;
            e.raiseupUv = 0;
            e.phone = 0;
            data.push(e);
          }else{
            e.shareUv = e.uv;
            e.sharePv = e.pv;
          }
        })
      };

      let raiseup = await this.$ZGManager.statSellerSpread({...params, evt: 'raiseup'});
      if(raiseup.status == 200 && raiseup.data.length > 0){
        raiseup.data.map((e) => {
          let index = data.findIndex(item => item.xid == e.id);
          if(index == -1){
            e.visitUv = 0;
            e.visitPv = 0;
            e.shareUv = 0;
            e.sharePv = 0;
            e.raiseupUv = e.pv;
            e.phone = 0;
            data.push(e);
          }else{
            e.raiseupUv = e.pv;
          }
        })
      };

      let phone = await this.$ZGManager.statSellerSpread({...params, evt: 'exchangephone'});
      if(phone.status == 200 && phone.data.length > 0){
        phone.data.map((e) => {
          let index = data.findIndex(item => item.xid == e.id);
          if(index == -1){
            e.visitUv = 0;
            e.visitPv = 0;
            e.shareUv = 0;
            e.sharePv = 0;
            e.raiseupUv = 0;
            e.phone = e.uv;
            data.push(e);
          }else{
            e.phone = e.uv;
          }
        })
      }
      this.tableData = data;
    },

    redirectPage(item){
      this.params.ranges = JSON.stringify(this.params.ranges);
      this.$router.push({
        path: '/analysis/detail',
        query: {
          ...this.params,
          xid: item.xid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.head-item{
  margin-bottom: 10px;
  .item-content{
    display: inline-block;
    margin-right: 30px;
    label{
      font-size: 14px;
      color: #666;
      margin-right: 5px;
    }
    ::v-deep .el-select{
      width: 160px;
    }
    ::v-deep .el-date-editor{
      width: 406px;
    }
  }
}
</style>